<template>
  <div>
    <el-card style="margin-bottom: 10px">
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="querifo.sendUser"
            size="medium"
            placeholder="发送者昵称，姓名"
          />
        </nav>
        <nav class="input">
          <el-input
            v-model="querifo.receiveUser"
            size="medium"
            placeholder="接收者昵称，姓名"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="querifo.source" placeholder="来源类型" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="消息" :value="0" />
            <el-option label="合作" :value="1" />
            <el-option label="圈子" :value="2" />
            <el-option label="企业号" :value="3" />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="querifo.status" placeholder="状态" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="未查看" :value="0" />
            <el-option label="已查看" :value="1" />
            <el-option label="已交换" :value="2" />
          </el-select>
        </nav>

        <nav class="input" style="width:auto">
          <!-- <label
            for=""
            style="font-size: 14px; float: left; transform: translateY(9px)"
          >发送日期：</label> -->
          <el-select v-model="timeState" @change="inTheAir" style="width: 150px;margin-right: 5px;" placeholder="状态" size="medium">
            <el-option label="发送时间" :value="1" />
            <el-option label="查看时间" :value="2" />
            <el-option label="交换时间" :value="3" />
          </el-select>
          <!-- <div class="box" style="width:300px"> -->
          <el-date-picker
            v-if="timeState==1"
            v-model="querifo.sendDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width:300px"
            size="medium"
          />
           <el-date-picker
            v-if="timeState==2"
            v-model="querifo.viewDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width:300px"
            size="medium"
          />
           <el-date-picker
            v-if="timeState==3"
            v-model="querifo.exchangeDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width:300px"
            size="medium"
          />
          <!-- </div> -->
        </nav>
        <nav class="input">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card class="box-card">
      <el-table
        :data="userlist"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column label="ID" prop="id" /> -->
        <el-table-column label="发送者">
          <template slot-scope="{ row }">
            <p>【UID:{{ row.sendUserId }}】{{ row.sendUser }}</p>
          </template>
        </el-table-column>
        <el-table-column label="接受者" prop="receiveUser">
          <template slot-scope="{ row }">
            <p>【UID:{{ row.receiveUserId }}】{{ row.receiveUser }}</p>
          </template>
        </el-table-column>
        <el-table-column label="来源类型" prop="source">
          <template slot-scope="{ row }">
            {{ filter_source(row.source) }}
          </template>
        </el-table-column>

        <el-table-column label="状态" prop="status">
          <template slot-scope="{ row }">
            <el-tag v-if="filter_status(row.status)" type="warning" size="mini">
              {{ filter_status(row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发送人手机号" prop="sendHidePhone">
          <template slot-scope="{ row }">
            {{ filter_HidePhone(row.sendHidePhone) }}
          </template>
        </el-table-column>
        <el-table-column label="接收人手机号" prop="receiveHidePhone">
          <template slot-scope="{ row }">
            {{ filter_HidePhone(row.receiveHidePhone) }}
          </template>
        </el-table-column>
        <el-table-column label="发送时间" prop="createTime" />
        <el-table-column label="查看时间" prop="viewTime" />
        <el-table-column label="交换时间" prop="exchangeTime" />

        </el-table-column>
        <!-- <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-setting"
              size="mini"
              @click="setRole(scope.row, 2)"
              v-show="$store.state.btn_if.indexOf('user_list_status') != -1"
              >审核通过
            </el-button>
            <el-button
              type="text"
              icon="el-icon-setting"
              size="mini"
              @click="showShk(scope.row)"
              v-show="$store.state.btn_if.indexOf('user_list_status') != -1"
              >审核不通过</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="querifo.page"
        :page-size="querifo.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
// import { getChannelList } from '../api/axiso.js'
import Remark from '../../components/remark/index'
import Dilog from '../../components/dilog.vue'
export default {
  components: {
    Remark,
    Dilog
  },
  data() {
    var checkEmail = (rule, value, cb) => {
      // 这是验证邮箱的正则表达式
      const regemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regemail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合格邮箱'))
    }
    var checkMoblle = (rule, value, cb) => {
      const regphone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (regphone.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }

    return {
      province: '',
      btn: true,
      timeState:1,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      shk: false,
      shkform: {},
      regDate: [],
      querifo: {
        sendUser: '',
        page: 1,
        pageSize: 10,
        receiveUser: '',
        status: '',
        source: '',
        sendDate: [],
        viewDate:[],
        exchangeDate:[]
      },
      order: '',
      userlist: [],
      total: 0,
      addDialogVisible: false,
      addform: {
        username: '',
        password: '',
        email: '',
        mobile: ''
      },
      addFormRulue: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
          // {
          //   min: 3,
          //   max: 10,
          //   message: '用户的长度在3~10个字符直接',
          //   trigger: 'blur'
          // }
        ],
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        businessInfo: [
          { required: true, message: '请输入业务介绍', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        job: [{ required: true, message: '请输入职位', trigger: 'blur' }],
        province: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        contactType: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ],
        type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
        business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '用户的长度在6~15个字符直接',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMoblle, trigger: 'blur' }
        ],
        realname: [{ required: true, message: '请输入昵称', trigger: 'blur' }]
      },
      editdialog: false,
      editform: {
        realName: '',
        nickname: '',
        email: '',
        phone: '',
        remark: '',
        waId: '',
        weixin: '',
        businessInfo: ''
      },
      setRoledingvisable: false,
      userinfo: '',
      // 所有角色数据列表
      roleslist: [],
      slectroleName: '',
      Config: this.$store.state.config,
      status: '',
      service: '',
      paiform: {},
      header: {
        Token: sessionStorage.getItem('token')
      },
      action: {
        action: 'user'
      },
      city: ''
    }
  },

  computed: {
    // user() {
    //   return function(i) {
    //     if (i >= 1 && this.Config) {
    //       if (this.Config['user_type']) {
    //         return this.Config['user_type'][i]
    //       }
    //     }
    //   }
    // },
    // user_1() {
    //   return function(i, i2) {
    //     // console.log(i2.split(','));
    //     var arr = i2.split(',')
    //     var str = ''
    //     if (i >= 1 && this.Config) {
    //       if (this.Config['user_business'] && this.Config['user_business'][i]) {
    //         arr.forEach(item => {
    //           if (item.length > 1) {
    //             str += this.Config['user_business'][i][item] + ','
    //           }
    //         })
    //         str = str.slice(0, str.length - 1)
    //         return str
    //       }
    //     }
    //   }
    // },
    citys() {
      return async function(val) {
        console.log(val)

        const { data: res } = await this.$http.get(
          '/admin/Area/getList?code=' + val
        )
        console.log(res)
        return { code: 5, sds: 3 }
        // .then(({ data: res }) => {
        //   if (res.errorCode == 200) {
        //     console.log(1);

        //   }
        // })
      }
    }
  },
  created() {
    this.getuserlist()
  },
  mounted() {},
  methods: {
    op() {
      this.getc()
      this.editform.city = ''
    },
    inTheAir(){
        this.querifo.sendDate=[]
        this.querifo.viewDate=[]
        this.querifo.exchangeDate=[]
    },
    showShk(row) {
      this.shk = true
      this.shkform = row
    },
    async handleInputBlur(row) {
      this.paiform.id = row.id
      this.paiform.remark = row.remark
      this.paiform.waId = row.waId.join(',')
      console.log(this.paiform)
      const { data: res } = await this.$http.post(
        '/admin/User/editInfo',
        this.paiform
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        //  row.pai=false
        //  this.tableData=[...this.tableData]
        this.getuserlist()
      } 
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    getc() {
      this.$http
        .get('/admin/Area/getList?code=' + this.editform.province)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(this.editform.province)
            console.log(res)
            this.city = res.data
          }
        })
    },
    // 这是过滤
    filter_source(val) {
      if (val == 1) {
        return '合作'
      } else if (val == 2) {
        return '圈子'
      } else if (val == 3) {
        return '企业号'
      } else if(val == 0){
        return '消息'
      }
    },
    filter_status(val) {
      if (val == 0) {
        return '未查看'
      } else if (val == 1) {
        return '已查看'
      } else if (val == 2) {
        return '已交换'
      } else if(val == 3){
        return '拒绝'
      }
    },
    filter_HidePhone(val) {
      if (val == 1) {
        return '已隐藏'
      } else {
        return '已展示'
      }
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'user_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'user_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getuserlist()
    },
    subimt() {
      this.querifo.page = 1
      this.getuserlist()
    },
    async getuserlist() {
      const { data: res } = await this.$http.get(
        '/admin/UserBusinessCardLog/getList',
        {
          params: {
            ...this.querifo,
            sendDate: this.querifo.sendDate.join('~'),
            viewDate: this.querifo.viewDate.join('~'),
            exchangeDate: this.querifo.exchangeDate.join('~'),
          }
        }
      )
      if (res.errorCode != 200) {
        return 
      }
      this.userlist = res.data.list
      this.userlist.forEach(item => {
        if (item.waId) {
          item.waId = item.waId.split(',')
        }
      })
      this.total = res.data.totalCount
      // console.log(res)
    },
    handleSizeChange(e) {
      // console.log(e)
      this.querifo.pageSize = e
      this.getuserlist()
    },
    handleCurrentChange(e) {
      // console.log(e)
      this.querifo.page = e
      this.getuserlist()
    },
    async userstatechange(userinfo) {
      // 监听switch开关状态改变
      // console.log(userinfo)
      const { data: res } = await this.$http.put(
        `users/${userinfo.id}/state/${userinfo.mg_state}`
      )
      // console.log(res)
      if (res.meta.status != 200) {
        userinfo.mg_state = !userinfo.mg_state
        return this.$message.error('更新用户失败')
      }
      this.$message.success('更新用户成功')
    },
    addUser() {
      this.$refs.addFormRef.validate(async val => {
        // console.log(val)
        if (!val) return
        const { data: res } = await this.$http.post('users', this.addform)
        if (res.meta.status != 201) {
          this.$message.error('添加用户失败')
        }
        this.$message.success('添加用户成功')
        this.addDialogVisible = false
        this.getuserlist()
      })
    },
    reset() {
         Object.assign(this.$data, this.$options.data.call(this))
      this.getuserlist()
    },
    async showEdit(id) {
      // console.log(id)
      this.editform.id = id
      const { data: res } = await this.$http.get('/admin/User/getById?id=' + id)
      if (res.errorCode != 200) {
        return this.$message.error('查询用户失败')
      }
      this.editform = {
        ...res.data,
        waId: !!res.data.waId && res.data.waId.split(','),
        showContacts:
          (!!res.data.showContacts && res.data.showContacts.split(',')) || []
      }
      // console.log(this.editform.business);

      this.editform.business = this.editform.business.split(',')
      console.log(this.editform.business)

      if (this.editform.province) {
        this.getc()
      }
      this.editdialog = true
    },
    clear() {
      this.editform.business = []
      console.log(this.editform)
    },
    edituserinfo() {
      const form = {
        ...this.editform,
        business: this.editform.business.toString(),
        waId: this.editform.waId.toString(),
        showContacts: this.editform.showContacts.toString()
      }
      // console.log(1)
      if (this.btn) {
        this.$refs.editFormRef.validate(async val => {
          if (!val) return
          const { data: res } = await this.$http.post('/admin/User/edit', form)
          if (res.errorCode == 200) {
            this.$message.success('更新成功')
            console.log(this.editform)
            this.editdialog = false
            this.getuserlist()
          } 
        })
      }
      // this.btn = false
    },

    async removeuserid(id) {
      // console.log(id)
      await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/User/del', { params: { id: id }})
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                if (this.total % 10 == 1) {
                  this.querifo.page--
                }
                this.getuserlist()
              } 
            })
            .catch(() => {
              return this.$message.error('删除用户失败')
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 这是展示分配角色的对话框
    setRole(row, info) {
      this.$http
        .post('/admin/User/cert', {
          ...row,
          certStatus: info
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getuserlist()
          } 
        })
    },
    saveinRole() {
      if (!this.slectroleName) {
        return this.$message.error('请选择你要分配的角色')
      }
      this.$http
        .put(`users/${this.userinfo.id}/role`, {
          rid: this.slectroleName
        })
        .then(() => {
          this.$message.success('更新角色成功')
          this.getuserlist()
          this.setRoledingvisable = false
        })
        .catch(() => {
          this.$message.error('更新角色失败!')
        })
    },
    setRoleclosed() {
      this.slectroleName = ''
    },
    handleAvatarSuccess(res) {
      this.editform.avatar = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  margin-left: 15px;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
